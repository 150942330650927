body {
  font-family: 'Roboto', sans-serif;
  background-color: #f8f7fa;
  color: #333;
}
.calendar {
  /* padding: 0px; */

  overflow: hidden;
  background-color: #f8f7fa;
  color: #333;
  top: 55px;
  min-width: 220px;
}
.calendar--nav {
    margin: 0px -15px 0px;
    background-color: #2b3135;
    color: #fff;
    height: 35px;
    position: relative;
}
.calendar--nav a {
    position: absolute;
    cursor: pointer;
    left: 10px;
    font-size: 32px;
    line-height: 1;
    top: 0px;
    width: 30px;
    text-align: center;
    display: inline-block;
    color: rgba(255, 255, 255, 0.4);
    -webkit-user-select: none;
    user-select: none;
}
.calendar--nav a:hover {
  color: #fff;
}
.calendar--nav a:last-child {
  left: auto;
  right: 10px;
}
.calendar--nav h1 {
    margin: -15px;
    position: absolute;
    left: 33px;
    right: 40px;
    text-align: center;
    cursor: pointer;
    font-weight: 400;
    font-size: 19px;
    line-height: 66px;
    user-select: none;
    color: white;
}
.calendar--nav small {
  font-weight: 300;
  font-size: 60%;
}
.calendar--days {
  font-size: 0;
}
.calendar--days span {
    width: 14.28571%;
    display: inline-block;
    text-align: center;
    -webkit-user-select: none;
    user-select: none;
    cursor: pointer;
    line-height: 30px;
    position: relative;
    font-size: 13px;
    margin-bottom: 5px;
}
.calendar--days span.label {
    font-weight: 700;
    color: white;
    font-size: 9px;
    cursor: initial;
    background: #3e464c;
    height: 25px;
    line-height: 25px;
}
.calendar--days span.active {
  font-weight: 700;
  background-color: rgba(182, 112, 244, 0.05);
  border-radius: 12px;
}
.calendar--days span.muted {
  color: rgba(0, 0, 0, 0.3);
}
.calendar--days span.between {
  border-radius: 0;
}
.calendar--days span.start, .calendar--days span.between, .calendar--days span.end {
  background-color: #5180ce;
  color: #fff;
}
.calendar--days span.start {
  border-radius: 12px 0 0 12px;
}
.calendar--days span.end {
  border-radius: 0 12px 12px 0;
}
.calendar--days span.start.end {
  border-radius: 12px;
}
.calendar--days span.between:nth-child(7n):after, .calendar--days span.start:nth-child(7n):after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 100%;
  background-color: #5180ce;
  width: 20px;
}
.calendar--days span.between:nth-child(7n + 1):after, .calendar--days span.end:nth-child(7n + 1):after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 100%;
  background-color: #5180ce;
  width: 20px;
}
.calendar--days span.start.end:after {
  display: none;
}
