button, div, h1, label, p, td {
    font-family: Roboto,sans-serif;
    font-weight: 300;
  
}
.inputClass {
    background: transparent;
    border: none;
    border-bottom: 1px solid white;
    width: 150px;
    margin-top: 25px;
    margin-bottom: 25px;
    padding-bottom: 0px;
    font-family: Roboto,sans-serif;
    font-weight: 400!important;
    font-size: 1em;
    padding: 0;

}
.inputClass:hover, .inputClass:focus, .inputClass:active
{
    background: transparent;
    border: none;
    border-bottom: 1px solid white;
    width: 150px;
    margin-top: 25px;
    box-shadow: none;
}
.selectClass {
    background: transparent;
    border: none;
    border-bottom: 1px solid white;
    width: 150px;
    margin-top: 25px;
    margin-bottom: 25px;
    padding-bottom: 0px;
    font-family: Roboto,sans-serif;
    font-weight: 400!important;
    font-size: 1em;
    padding: 0;

}
.selectBody {
    
    font-family: Roboto,sans-serif;
    font-weight: 400!important;
    font-size: 1em;
    text-align: left;
    color: white;

}
.selectBody td {
    width: 100%;
    text-align: left;
}
.headerCol {
    flex: 1 1 100%;
    box-sizing: border-box;
    flex-direction: column;
    display: flex;
    place-content: center flex-start;
    align-items: center;
    max-width: 20%;
  }
  
  /* Media query for screens smaller than 768px (mobile devices) */
  @media screen and (max-width: 767px) {
    .headerCol {
      max-width: 50%;
    }
  }
  
  /* Media query for screens between 768px and 1024px (tablets) */
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    .headerCol {
      max-width: 33%;
    }
  }
  
  /* Media query for screens larger than 1024px (desktops) */
  @media screen and (min-width: 1025px) {
    .headerCol {
      max-width: 20%;
    }
  }
.headerTitle {
    margin-top: 45px;
    text-align: center;
    display: block;
    font-size: 2em;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    color: white;
    font-family: Roboto,sans-serif;
    font-weight: 300!important;
    padding-top: 20px;
  
}
.pd10S {
    padding: 0 15px;
    justify-content: center;
    align-items: center;
    display: flex;
    height: 75px;
}
.pd20 {
    padding:20px
}
.btnDark {
    overflow: hidden;
    background-color: #3e464c;
    color: #fff;

    border: 0;
    -webkit-box-shadow: 0 1px 2.5px 0 rgb(0 0 0 / 26%), 0 1px 5px 0 rgb(0 0 0 / 16%);
    box-shadow: 0 1px 2.5px 0 rgb(0 0 0 / 26%), 0 1px 5px 0 rgb(0 0 0 / 16%);
    -webkit-transition: background-color .3s;
    transition: background-color .3s;
}
.btnLight {
    background-color: #5bc0de;
    border-color: #5bc0de;
    color: #fff;
}
.btnLight:focus, .btnDark:focus{
    outline: 0;
    background-color: #556068;
    color: white;
    border-color: none;
}
.btnDark:active, .btnDark:hover  {
    outline: 0;
    background-color: #556068;
    color: white;
}

.ant-btn[disabled], .ant-btn[disabled]:hover, .ant-btn[disabled]:focus, .ant-btn[disabled]:active{
    opacity: .35;
    filter: Alpha(Opacity=35);
    background-image: none;
    cursor: auto!important;
    color: white !important;
    border: none !important;
    background: #3e464c !important;
    text-shadow: none;
    box-shadow: none;
}
.btnLight[disabled]{
    background: #5bc0de !important;
}
.ant-popover-placement-bottom, .ant-popover-placement-bottomLeft, .ant-popover-placement-bottomRight {
    /* padding-top: 0px; */
}
.ant-popover {
    /* margin-top: -20px !important; */
}
.abc {
    flex-direction: row;
    box-sizing: border-box;
    display: flex;
    max-height: 100%;
    place-content: center;
    align-items: center;

}
.adsParent {
    flex-direction: row;
    box-sizing: border-box;
    max-height: 100%;
    place-content: flex-start center;
    align-items: flex-start;
    display: flex;
}
.adsChill {
    flex: 1 1 100%;
    box-sizing: border-box;
    flex-direction: column;
    display: flex;
    max-width: 50%;
}
.adsTitle {
    color:#337ab7!important;
    font-weight:bold !important;
    font-size: 14px;
    margin-top: -2px;
}
.adsTitle:hover {
    color: #01b2fe!important;
}
.subTitle {
    margin-top: -15px;
    font-size: 11px;
    line-height: 11px;
}
.iconHeader svg {
    width: 50px;
    height: 50px;
}
.emoticon {
    min-width: 18px!important;
    width: 18px!important;
    height: 18px!important;
    background-repeat: no-repeat;
    margin-left: 2px;
}
.ant-tooltip-inner {
    min-width: 28px;
    min-height: 20px;
    padding: 0px 8px;
    color: #fff;
    text-align: center;
    text-decoration: none;
    word-wrap: break-word;
    background-color: rgba(0, 0, 0, 0.75);
    border-radius: 2px;
    box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
    margin-top: -15px;
}
.ant-tooltip-arrow {
    position: absolute;
    z-index: 2;
    display: block;
    width: 15px;
    height: 15px;
    overflow: hidden;
    background: transparent;
    pointer-events: none;
}

.actionLinkLinkTitle {
    font-size: 18px;
    font-family: Georgia,serif;
    font-weight: 500;
    color: #1d2129;
}
.actionLinkLinkDescription {
    font-size: 12px;
    font-family: Helvetica,Arial,sans-serif;
    font-weight: 500;
    color: #1d2129;
}
video {
    object-fit: contain;
    overflow-clip-margin: content-box;
    overflow: clip;
}
.ant-popover-inner-content {
    padding: 0px !important;
    min-width: 150px;
    min-height: 32px;
}
.item-background {
    background: white;
    padding: 6px 10px;
    font-weight: 400 !important;
    border: 0;
    cursor: pointer;
    text-align: left;
    font-size:  14px;
}
.ant-popover-title {
    display: none;
}
.item-background:hover, .item-background:active, .item-background:focus{
    background-color: #e8e8e8;
}
.item-radio {
    padding: 10px;
    font-weight: 400 !important;
    border: 0;
    cursor: pointer;
    margin: -5px 0;
    text-align: left;
    font-size:  14px;
    width: 100%;
}
.ant-radio-checked .ant-radio-inner {
    border-color: #000000;
}
.ant-radio-inner::after {
    background-color: #000000;
}
.item-slider {
    padding: 15px;
    border: 0;
    cursor: pointer;
    margin: -5px 0;
    text-align: left;
    font-size: 14px;
}
.ant-slider-handle {

    background-color: #5180ce;
    border: solid 2px #5180ce;
 
}
.ant-slider-step {
    position: absolute;
    width: 100%;
    height: 2px;
    background: #cccc;
    pointer-events: none;
}
.ant-slider-track {
    position: absolute;
    height: 2px;
    background-color: #5180ce;
    border-radius: 2px;
    transition: background-color 0.3s;
    z-index: 2;
}
.ant-slider-handle:focus, .ant-slider-handle:active, .ant-slider-handle:hover {
    border-color: #5180ce;
    outline: none;
    box-shadow: none !important;
}
.ant-slider {

    margin: 10px 0px 10px;

}
.inputSearch {
    background: #3e464c;
    color: white;
}
.inputSearch input{
    background: transparent;
    color: white;
}
.ant-checkbox-checked .ant-checkbox-inner {
    background-color: black;
    border-color: black;
}
.itemInputTitle {
    font-weight: bold !important;
}
.itemInput {
    border: none;
    border-bottom: 1px solid #cccc;
    box-shadow: none !important;
}
.itemInput:focus, .itemInput:active, .itemInput:hover {
    border: none;
    border-bottom: 1px solid black;
    box-shadow: none !important;
}
.selectEdit {
    width: 200px;
    border: none;
    border-bottom: 1px solid #cccc;
    box-shadow: none;
    text-align: left;
}

.selectEdit .ant-select-selector {
    border: none !important;
    background: transparent !important;
    box-shadow: none !important;
    padding: 0 !important;
}
.selectBody .ant-select-selection-placeholder {
    color: black;
}
.ui-table table {
    border-collapse: collapse;
    width: 100%;
    table-layout: fixed;
    text-indent: initial;
    border-spacing: 2px;
    border-color: grey;
}
.ui-widget, .ui-widget * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
.ui-table-thead>tr>th {
    background: #fff;
    border: 1px solid #cacaca;
}
.ui-table-tbody>tr>td {
    background: inherit;
    border: 1px solid #cacaca;
}
tbody {
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
}
tr {
    display: table-row;
    vertical-align: inherit;
    border-color: inherit;
}
.ui-table-tbody>tr:nth-child(odd) {
    background-color: #f4f4f4;
}
.ui-table-tbody>tr>td, .ui-table-tfoot>tr>td, .ui-table-thead>tr>th {
    padding: 0.625em 1em;
    font-weight: 400;
}
.ant-picker-panels > *:first-child button.ant-picker-header-next-btn {
    visibility: visible !important;
  }
  
  .ant-picker-panels > *:first-child button.ant-picker-header-super-next-btn {
    visibility: visible !important;
  }
  
  .ant-picker-panels > *:last-child {
    display: none !important;
  }
  
  .ant-picker-panel-container, .ant-picker-footer {
    width: 280px !important;
  }
  
  .ant-picker-footer-extra > div {
    flex-wrap: wrap !important; 
  }
.abcd {
    visibility: hidden !important;
    margin-top: -56px;
}
.ant-picker-range-arrow {
    display: none !important
}
.popup-picker {
    min-width: 200px;
    position: absolute;
    z-index: 10000;
    top: 60px;
    background: white;
    box-shadow: 1px 1px 20px 0 rgb(0 0 0 / 40%);
}
.ant-checkbox-group-item {
    width: 100%;
    background: white;
    padding: 6px 10px;
    font-weight: 400 !important;
    border: 0;
    cursor: pointer;
    text-align: left;
    font-size: 14px;
}
/* .ant-checkbox-group-item {
    width: 100%;
    background: white;
    padding: 6px 10px;
    font-weight: 400 !important;
    border: 0;
    cursor: pointer;
    text-align: left;
    font-size: 14px;
} */

.ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0px !important;
}
.ant-checkbox-group {
    width: 100%;
}
.panelFilter {
    background: white;
    width: auto;
    text-align: center;
    display: inline-block;
    padding: 10px;
    margin: 5px;
    border-radius: 5px;
}
.menu {
    display: flex;
}
.menu > div {
    padding: 0 20px;

}
.menu > div > a > span {
    color: #ddddddcc !important;
}
.menu > div > a > span:hover {
    color: white !important;
}
.menu_mobile {
    width: 200px;
}
.menu_mobile > div {
    padding: 10px 20px;
}
.menu_mobile > div > a > span {
    color: black !important;
}
.save_search {
    border: 1px solid #cccc;
    padding: 10px;
    box-shadow: 1px 1px 20px 0 rgb(116 116 116 / 23%);
    font-size: 12px;
}
.save_search > div {

    padding: 0px !important;

}
.save_search_list > div {

    padding: 0px !important;

}
.table_save_filter thead {
    text-align: left;
    border-bottom: 1px solid #cccc;
}
.table_save_filter tr{
    border-bottom: 1px solid #cccc;
}
.table_save_filter thead tr th {
    text-align: left;
    padding:0px 0px 5px 15px;
}
.loading_ads img{
    position: absolute;
    top: 50%;
    left: 50%;
    width: 150px;
    height: 150px;
}
.ant-modal-content {

    border-radius: 6px !important;

}
.ant-modal-header {
    background: #2d2e2f !important;
}
.ant-modal-title {
    color: white !important;
}
.ant-modal-close-x {
    color: white !important;
}
.alert_label {
    height: 40px;
    width: 100%;
    padding: 10px;
    background: white;
    text-align: center;
    font-weight: 500;
    position: fixed;
    opacity: 1;
    z-index: 888;
    box-shadow: rgb(0 0 0 / 16%) 0px 2px 10px 0px, rgb(0 0 0 / 26%) 0px 2px 5px 0px;
}